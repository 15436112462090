/* eslint-disable import/no-duplicates */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import {
  addMonths,
  addYears,
  differenceInMonths,
  format,
  parseISO,
} from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { FiCheck, FiCheckCircle, FiDollarSign } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import {
  Container,
  Content,
  InfoValuesBox,
  GraphWrapper,
  BoxButtons,
  ColoredBoxInfo,
  ReceiveBox,
  ButtonSelectBox,
  ButtonSimulationCalc,
  BtnVoltar,
  ButtonSimulationValue,
} from './styles'
import simulationYearsInfo from '../../assets/simulation-years-info.svg'
import simulationPercentageInfo from '../../assets/simulation-percentage-info.svg'
import simulationSelectTimeIcon from '../../assets/simulation-select-time-icon.svg'
import simulationSelectPercentIcon from '../../assets/simulation-select-percent-icon.svg'
import simulationFixedValue from '../../assets/simulation-select-fixed-value.svg'
import usePersistedState from '../../hooks/usePersistedState'
import { formatValue } from '../../utils/formatValues'
import Button from '../../components/Button'
import { UserData, UserDetails } from '../../utils/interfaces'
import Header from '../../components/Header'
import { Valor, ValorMascarar } from '../../utils/masks'
import calculaIdade from '../../utils/calculaIdade'
import Input from '../../components/Input'
import getValidationErrors from '../../utils/getValidationErrors'
import InputHidden from '../../components/InputHidden'

interface CustomTooltipInterface {
  active: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
  label: string
}

interface Values {
  year: number
  anomes: string
  invested: number
  finalBalance: number
  rentability: number
}

const Simulation: React.FC = () => {
  const [userDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [totalBalance, setTotalBalance] = usePersistedState<number>(
    'totalBalance',
    0,
  )
  const [timeValueYears, setTimeValueYears] = usePersistedState<number>(
    'TimeValueYears',
    5,
  )
  const [rendaFixa, setRendaFixa] = usePersistedState('RendaFixaValue', 0)
  const [percentualValuePercent, setPercentualValuePercent] = usePersistedState<
    number
  >('PercentualValuePercent', 0.5)

  const [selectedReceive, setSelectedReceive] = usePersistedState<
    'PD' | 'PS' | 'VF' | ''
  >('receiveTypeSelected', '')

  const [flagEdit] = usePersistedState<'S' | 'N'>('flagEdit', 'N')
  const [aportFlag] = usePersistedState('aportFlag', false)
  const [displayPercent, setDisplayPercent] = useState(selectedReceive === 'PS')
  const [displayTime, setDisplayTime] = useState(selectedReceive === 'PD')
  const [displayValorFixo, setDisplayValorFixo] = useState(
    selectedReceive === 'VF',
  )

  const [totalInvested, setTotalInvested] = useState(0)
  const [values, setValues] = useState<Values[]>([])
  const [launched, setLaunched] = useState(false)
  const URP = 753.24 // 725.23 // 687.61

  const { salario } = userDetails

  const history = useHistory()

  const idadeAtual = calculaIdade(userData.birthdate)

  const percentualValue = useMemo(
    () => formatValue(totalBalance * (percentualValuePercent / 100)),
    [percentualValuePercent, totalBalance],
  )
  const timeValue = useMemo(
    () => formatValue(totalBalance / (timeValueYears * 12)),
    [timeValueYears, totalBalance],
  )

  const formRef = useRef<FormHandles>(null)

  /* *** PERCENTUAL SOBRE SALDO *** */
  const minPctSobreSaldo = useMemo(() => {
    const m = parseFloat(((URP * 100) / totalBalance).toFixed(1))
    const v = (totalBalance * m) / 100
    const t = v < URP ? parseFloat((m + 0.1).toFixed(1)) : m
    return t
  }, [URP, setPercentualValuePercent, totalBalance])

  const maxTpoDeterminado = useMemo(() => {
    const m = totalBalance / URP
    const t = Math.trunc(m / 12)
    return t
  }, [totalBalance])

  const createValues = useCallback(() => {
    const valuesCalculated: Values[] = []
    const minCtb = URP * 0.14
    let totalInvestedTemp = totalInvested
    let temporaryFinalBalance = 0
    let salarioAtualizado = salario
    const hoje = new Date()
    const dtIniCtb = hoje.getDay() <= 15 ? hoje : addMonths(hoje, 1)

    let contribSupPartic =
      salarioAtualizado <= URP * 7
        ? salarioAtualizado * (userDetails.pctContribuicaoSuplementar / 100)
        : (salarioAtualizado - URP * 7) *
          (userDetails.pctContribuicaoSuplementar / 100)

    contribSupPartic =
      contribSupPartic < minCtb &&
      salarioAtualizado > URP * 7 &&
      contribSupPartic > 0
        ? minCtb
        : contribSupPartic

    let contribAdicPartic =
      salarioAtualizado * (userDetails.pctContribuicaoAdicional / 100)

    let contribSupPatroc = contribSupPartic * 2
    // let contribBasicPatroc =
    //   salarioAtualizado <= URP * 7 ? 0 : (salarioAtualizado - URP * 7) * 0.05

    const dataAposentadoria = addYears(
      parseISO(userData.birthdate),
      userDetails.age,
    )

    const monthsContrib = differenceInMonths(dataAposentadoria, new Date())
    const totalContribution = monthsContrib
    const rentability = (1 + 0.05) ** (1 / 12) - 1 // 0.407412 // (1 + 5) ** (1 / 12 - 1) // 5% ao ano.
    let finalBalance = 0
    let counter = 1
    let idade = idadeAtual

    for (counter; counter <= totalContribution + 1; counter += 1) {
      const dataCorrente = addMonths(dtIniCtb, counter)
      const mesCorrente = dataCorrente.getMonth() + 1
      const anoCorrente = dataCorrente.getFullYear()

      /* EVOLUÇÃO SALARIAL */
      if (mesCorrente === 1) {
        salarioAtualizado += salarioAtualizado * 0.03

        contribSupPartic =
          salarioAtualizado <= URP * 7
            ? salarioAtualizado * (userDetails.pctContribuicaoSuplementar / 100)
            : (salarioAtualizado - URP * 7) *
              (userDetails.pctContribuicaoSuplementar / 100)

        contribSupPartic =
          contribSupPartic < minCtb &&
          salarioAtualizado > URP * 7 &&
          contribSupPartic > 0
            ? minCtb
            : contribSupPartic

        contribAdicPartic =
          salarioAtualizado * (userDetails.pctContribuicaoAdicional / 100)

        // contribSupPatroc = salarioAtualizado <= URP * 7 ? 0 : contribSupPartic
        contribSupPatroc = contribSupPartic * 2

        // contribBasicPatroc =
        //   salarioAtualizado <= URP * 7
        //     ? 0
        //     : (salarioAtualizado - URP * 7) * 0.05

        idade += 1
      }

      const monthTotalInvested =
        contribSupPartic + contribAdicPartic + contribSupPatroc
      // (idade >= 62 ? 0 : contribSupPatroc + contribBasicPatroc)

      totalInvestedTemp += monthTotalInvested

      finalBalance =
        temporaryFinalBalance * rentability +
        (monthTotalInvested + temporaryFinalBalance)

      temporaryFinalBalance = finalBalance

      let anomesFormat = format(dataCorrente, "MMMM' / 'yyyy", { locale: ptBR })
      anomesFormat = anomesFormat[0].toUpperCase() + anomesFormat.substring(1)

      // console.log(
      //   anomesFormat,
      //   salarioAtualizado,
      //   contribSupPartic,
      //   totalBalance,
      // )

      const val: Values = {
        // eslint-disable-next-line no-bitwise
        year: anoCorrente,
        anomes: anomesFormat,
        invested: totalInvestedTemp,
        rentability,
        finalBalance,
      }
      valuesCalculated.push(val)
    }

    setTotalInvested(totalInvestedTemp)
    setTotalBalance(temporaryFinalBalance)
    setValues(valuesCalculated)
    setLaunched(true)
  }, [
    idadeAtual,
    salario,
    setTotalBalance,
    totalInvested,
    userData.birthdate,
    userDetails.age,
    userDetails.pctContribuicaoAdicional,
    userDetails.pctContribuicaoSuplementar,
  ])

  const toggleSelectedReceive = useCallback(
    (selectedReceiveNow: 'PD' | 'PS' | 'VF' | '') => {
      setSelectedReceive(selectedReceiveNow)
      if (selectedReceiveNow === 'PD') {
        setDisplayTime(true)
        setDisplayPercent(false)
        setDisplayValorFixo(false)
      } else if (selectedReceiveNow === 'PS') {
        setDisplayTime(false)
        setDisplayPercent(true)
        setDisplayValorFixo(false)
      } else if (selectedReceiveNow === 'VF') {
        setDisplayTime(false)
        setDisplayPercent(false)
        setDisplayValorFixo(true)
      } else {
        setDisplayPercent(false)
        setDisplayTime(false)
        setDisplayValorFixo(false)
      }
    },
    [setSelectedReceive],
  )

  const adicPerct = useCallback(() => {
    if (percentualValuePercent >= 3) {
      setPercentualValuePercent(3)
    } else {
      setPercentualValuePercent(
        (percentualValuePercent < minPctSobreSaldo
          ? minPctSobreSaldo
          : percentualValuePercent) + 0.1,
      )
    }
  }, [percentualValuePercent, setPercentualValuePercent, minPctSobreSaldo])

  const tiraPerct = useCallback(() => {
    if (
      percentualValuePercent <= (minPctSobreSaldo > 3 ? 3 : minPctSobreSaldo)
    ) {
      setPercentualValuePercent(minPctSobreSaldo > 3 ? 3 : minPctSobreSaldo)
    } else {
      setPercentualValuePercent(percentualValuePercent - 0.1)
    }
  }, [percentualValuePercent, minPctSobreSaldo, setPercentualValuePercent])

  const adicAno = useCallback(() => {
    if (timeValueYears >= maxTpoDeterminado) {
      setTimeValueYears(maxTpoDeterminado)
    } else {
      setTimeValueYears(timeValueYears >= 20 ? 20 : timeValueYears + 1)
    }
  }, [maxTpoDeterminado, setTimeValueYears, timeValueYears])

  const tiraAno = useCallback(() => {
    if (timeValueYears <= 5) {
      setTimeValueYears(5)
    } else {
      setTimeValueYears(timeValueYears - 1)
    }
  }, [setTimeValueYears, timeValueYears])

  const mudarRendaFixa = useCallback(
    valor => {
      const v = valor.replace(',', '').replaceAll('.', '')
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(v) || v === '' || v === undefined) {
        setRendaFixa(rendaFixa)
      } else {
        const m = Math.floor(v.length - 2)
        const a = `${v.substr(0, m)}.${v.substr(m)}`
        const f = parseFloat(a)
        setRendaFixa(f)
      }
    },
    [rendaFixa, setRendaFixa],
  )

  useEffect(() => {
    if (!launched) createValues()
  }, [createValues, launched])

  const graphValues = values.map(value => ({
    year: value.year,
    value: value.finalBalance,
    formattedValue: Valor(value.finalBalance.toFixed(2)),
  }))

  const CustomTooltip = useCallback(
    ({ active, payload, label }: CustomTooltipInterface) => {
      if (active) {
        return (
          <div className="tooltip">
            <h4>Ano: {label}</h4>
            <p>
              Saldo: <span>{Valor(payload[0].value.toFixed(2))}</span>
            </p>
          </div>
        )
      }
      return null
    },
    [],
  )

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          rendaFixa: Yup.string().when('tipo', {
            is: 'VF',
            then: Yup.string()
              .required('Campo obrigatório')
              .test('', 'Campo obrigatório', () => rendaFixa > 0)
              .test(
                '',
                `Escolha um valor entre ${formatValue(
                  totalBalance * 0.001,
                )} e ${formatValue(totalBalance * 0.03)}`,
                () =>
                  (totalBalance * 0.001 <
                    parseFloat(
                      data.rendaFixa.replace('.', '').replaceAll(',', '.'),
                    ) &&
                    parseFloat(
                      data.rendaFixa.replace('.', '').replaceAll(',', '.'),
                    ) <
                      totalBalance * 0.03) ||
                  data.rendaFixa === undefined,
              ),
          }),
        })

        if (data.tipo === 'PD' || data.tipo === 'PS') {
          setRendaFixa(0)
        }
        await schema.validate(data, { abortEarly: false })

        if (flagEdit === 'S') {
          history.push('/resume')
        } else if (aportFlag === true) {
          history.push('/aport-confirmation')
        } else {
          history.push('/register')
        }
      } catch (err) {
        formRef.current?.setErrors(
          getValidationErrors(err as Yup.ValidationError),
        )
      }
    },
    [aportFlag, flagEdit, history, rendaFixa, setRendaFixa, totalBalance],
  )

  const handleConfirmValues = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <div className="values-box">
            <InfoValuesBox color="magenta" gradientDirection="right">
              <span>Valor investido</span>
              <h3>{formatValue(totalInvested)}</h3>
              <small>Valor investido no período </small>
            </InfoValuesBox>

            <InfoValuesBox color="darkgreen" gradientDirection="left">
              <span>Seu saldo projetado</span>
              <h3>{formatValue(totalBalance)}</h3>
              <small>Valor investido + rentabilidade </small>
            </InfoValuesBox>
          </div>
          <article>
            <p>
              Quer ver a evolução do seu saldo?
              <br />
              Arraste o mouse no gráfico e descubra.
              <br />
              Não se preocupe neste momento, é apenas uma simulação.
            </p>
          </article>

          <GraphWrapper>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={graphValues}>
                <defs>
                  <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#4f78e0" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#4f78e0" stopOpacity={0.05} />
                  </linearGradient>
                </defs>

                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#0b2d84"
                  fillOpacity={1}
                  fill="url(#color)"
                />

                <Tooltip
                  content={
                    <CustomTooltip active={false} payload={2} label="" />
                  }
                />
                <XAxis name="Ano" dataKey="year" />
                <YAxis
                  name="Saldo"
                  dataKey="value"
                  width={75}
                  axisLine={false}
                  tickLine={false}
                  tickCount={5}
                  tickFormatter={number => `${Valor(number.toFixed(2))}`}
                />
                <CartesianGrid
                  strokeDasharray="3 3"
                  vertical={false}
                  opacity={0.5}
                />
              </AreaChart>
            </ResponsiveContainer>
          </GraphWrapper>
          <small>
            Os valores são simulações e não há garantia de rentabilidade futura.
            Para a projeção acima foi utilizado o percentual de 5% a.a.
          </small>
        </Content>

        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            rendaFixa: ValorMascarar(rendaFixa.toFixed(2).toString()),
          }}
        >
          <Content>
            <strong>
              Simule a forma de recebimento mensal <br /> do seu benefício:
            </strong>
            <ReceiveBox>
              <ButtonSelectBox
                type="button"
                onClick={() => toggleSelectedReceive('PS')}
                selected={selectedReceive === 'PS'}
              >
                <img src={simulationSelectPercentIcon} alt="Percentual" />
                <span>Percentual sobre o saldo</span>
                <FiCheckCircle />
              </ButtonSelectBox>
              <BoxButtons displayed={displayPercent}>
                <ButtonSimulationCalc type="button" onClick={() => tiraPerct()}>
                  -
                </ButtonSimulationCalc>
                <ButtonSimulationValue type="button">
                  <span>
                    {parseFloat(
                      (percentualValuePercent === null
                        ? 0
                        : percentualValuePercent
                      ).toFixed(2),
                    )}
                    %
                  </span>
                </ButtonSimulationValue>
                <ButtonSimulationCalc type="button" onClick={() => adicPerct()}>
                  +
                </ButtonSimulationCalc>
              </BoxButtons>
              <ColoredBoxInfo
                size="large"
                color="magenta"
                gradientDirection="left"
                displayed={displayPercent}
              >
                <div>
                  <h3>{percentualValue}*</h3>
                  <small>Valor recebido por mês</small>
                  <p>
                    *Valor simulado referente a{' '}
                    {(percentualValuePercent === null
                      ? 0
                      : percentualValuePercent
                    ).toFixed(1)}
                    % do seu saldo projetado, com pagamentos mensais efetuados
                    com base no percentual que escolher no momento da
                    aposentadoria.
                  </p>
                </div>
                <img src={simulationPercentageInfo} alt="Percentual" />
              </ColoredBoxInfo>
            </ReceiveBox>
            <ReceiveBox>
              <ButtonSelectBox
                type="button"
                onClick={() => toggleSelectedReceive('PD')}
                selected={selectedReceive === 'PD'}
              >
                <img src={simulationSelectTimeIcon} alt="Tempo" />
                <span>Prazo determinado</span>
                <FiCheckCircle />
              </ButtonSelectBox>
              <BoxButtons displayed={displayTime}>
                <ButtonSimulationCalc type="button" onClick={() => tiraAno()}>
                  -
                </ButtonSimulationCalc>
                <ButtonSimulationValue type="button">
                  <span>{timeValueYears} anos</span>
                </ButtonSimulationValue>
                <ButtonSimulationCalc type="button" onClick={() => adicAno()}>
                  +
                </ButtonSimulationCalc>
              </BoxButtons>
              <ColoredBoxInfo
                size="large"
                color="darkgreen"
                gradientDirection="left"
                displayed={displayTime}
              >
                <div>
                  <h3>{timeValue}*</h3>
                  <small>Valor recebido por mês</small>
                  <p>
                    *Valor simulado referente a modalidade de prazo determinado
                    com duração de {timeValueYears} anos calculado com base no
                    seu saldo projetado.
                  </p>
                </div>
                <img src={simulationYearsInfo} alt="Tempo" />
              </ColoredBoxInfo>
            </ReceiveBox>
            <ReceiveBox>
              <ButtonSelectBox
                type="button"
                onClick={() => toggleSelectedReceive('VF')}
                selected={selectedReceive === 'VF'}
              >
                <img src={simulationFixedValue} alt="Renda Fixa" />
                <span>Valor Fixo</span>
                <FiCheckCircle />
              </ButtonSelectBox>
              <BoxButtons displayed={displayValorFixo}>
                <Input
                  icon={FiDollarSign}
                  name="rendaFixa"
                  mask="currency"
                  type="text"
                  placeholder="Quero receber por mês:"
                  onChange={e => mudarRendaFixa(e.target.value)}
                />
              </BoxButtons>
              <ColoredBoxInfo
                size="large"
                color="orange"
                gradientDirection="left"
                displayed={displayValorFixo}
              >
                <div>
                  <h3>{formatValue(rendaFixa)}*</h3>
                  <small>Você receberá por mês</small>
                  <p>
                    *Renda mensal em valor fixo, expresso em moeda corrente
                    nacional.
                  </p>
                </div>
                <img src={simulationYearsInfo} alt="Tempo" />
              </ColoredBoxInfo>
            </ReceiveBox>
            <InputHidden name="tipo" type="hidden" value={selectedReceive} />
            <small className="comment">
              Selecione uma das opções acima para simular o valor que você
              receberá mensalmente ao se aposentar.
            </small>
            <br />
            <small>
              <b>
                Caso o benefício da renda mensal seja inferior à 1 URP (R$
                {ValorMascarar(URP.toFixed(2))}), o saldo poderá ser
                transformado em pagamento único.
              </b>
            </small>
          </Content>
        </Form>
        <Button
          type="button"
          fontSize="normal"
          color="orange"
          width="large"
          onClick={handleConfirmValues}
          disabled={!selectedReceive}
        >
          <FiCheck size={40} />É isso que eu quero!
        </Button>

        <BtnVoltar type="button" onClick={() => history.push('/')}>
          &lt; Quero alterar os valores e simular novamente
        </BtnVoltar>
      </Container>
    </>
  )
}

export default Simulation
