export default {
  title: 'light',
  colors: {
    primary: '#0b2d84',
    secondary: '#FE5A17',
    tertiary: '#0189B4',

    background: '#F7F8FE',
    backgroundSecondary: '#EAEBEE',
    backgroundTertiary: '#D9DADD',

    placeholder: '#666360',
    text: '#333',
    textSecondary: '#F5F5F5',

    golden: '#F29602', /*'#F29602',*/

    mainColor: '#FE5A17',
    secondaryColor: '#FFC291',

    error: '#C53030',
    errorText: '#fddede',

    success: '#28A745',
    sucessText: '#CAFFD5',
  },
}
