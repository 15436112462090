/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Base64 } from 'js-base64'
import Switch from 'react-switch'
import moment from 'moment'
import * as Yup from 'yup'

import { FiCalendar, FiSmartphone, FiMail, FiUser } from 'react-icons/fi'
import { MdSecurity } from 'react-icons/md'

import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import validaCPF from '../../utils/validaCPF'
import api from '../../services/api'

import InputHidden from '../../components/InputHidden'
import Button from '../../components/Button'
import Header from '../../components/Header'
import Input from '../../components/Input'

import { UserData, ConfigData, ErroProps } from '../../utils/interfaces'
import {
  Container,
  Content,
  RadioButton,
  BtnContato,
  Line,
  BtnModal,
} from './styles'
import calculaIdade from '../../utils/calculaIdade'
import ModalBox from '../../components/Modal'

const Register: React.FC = () => {
  const [configData, setConfigData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )

  const [acceptTerms, setAcceptTerms] = usePersistedState('acceptTerms', false)

  const [tipoContato, setTipoContato] = useState('S')

  const [clicado, setClicado] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [txtModal, setTxtModal] = useState('')
  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  // const handleAdmissao = useCallback(
  //   async (cpf) => {
  //     const parametros = Base64.encode(
  //       `{
  //         "cliente": "${configData.codCliente}",
  //         "cpf": "${cpf.replaceAll('.', '').replace('-', '')}"
  //       }`,
  //     )

  //     const parametrosFinal = Base64.encode(parametros)

  //     await api
  //       .get(`wsAdmissao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
  //       .then(res => {
  //         setDtAdm(res.data.dataAdmissao)
  //       })
  //       .catch(() => {
  //         alert('Ops, algo deu errado. Tente novamente mais tarde.')
  //       })
  //   }, [])

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Seu nome é obrigatório.')
            .matches(/\s/g, 'Digite o nome completo')
            .min(3, 'Digite o nome completo'),
          cpf: Yup.string()
            .required('Digite o CPF do titular')
            .test(
              '',
              'CPF inválido',
              () =>
                validaCPF(data.cpf.replaceAll('.', '').replace('-', '')) ||
                data.cpf === '',
            ),
          admission: Yup.string()
            .required('Data de admissão obrigatória')
            .min(10, 'Data de admissão deve seguir o formato dd/mm/aaaa.')
            .test(
              '',
              'A data de admissão não pode ser maior que hoje.',
              () =>
                moment() >
                moment(data.admission.split('/').reverse().join('-')) ||
                data.admission === '',
            )
            .test(
              '',
              'Data de admissão inválida',
              () =>
                moment(
                  data.admission.split('/').reverse().join('-'),
                ).isValid() || data.admission === '',
            )
            .test(
              '',
              'Data de admissão inválida',
              () =>
                calculaIdade(data.admission.split('/').reverse().join('-')) <=
                115 || data.admission === '',
            ),
          tipContato: Yup.string().required(),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
          phone: Yup.string()
            .max(15, 'Telefone inválido')
            .min(14, 'Telefone inválido')
            .required('Celular obrigatório'),
        })

        await schema.validate(data, { abortEarly: false })

        const aa = data.admission
        const dia = aa.split('/')[0]
        const mes = aa.split('/')[1]
        const ano = aa.split('/')[2]

        const dataForm = `${ano}-${`0${mes}`.slice(-2)}-${`0${dia}`.slice(-2)}`

        await setUserData({
          ...userData,
          name: data.name,
          cpf: data.cpf,
          admission: dataForm,
          email: data.email,
          phone: data.phone,
        })

        const parametros = Base64.encode(
          `{"versao":"${configData.tipo}",
            "plano": "${configData.plano}",
            "cliente":"${configData.codCliente}",
            "cpf":"${data.cpf.replaceAll('.', '').replace('-', '')}",
            "email":"${data.email === undefined ? '' : data.email}",
            "telefone":"${data.phone === undefined
            ? ''
            : `55${data.phone
              .replace('(', '')
              .replace(') ', '')
              .replace('-', '')}`
          }",
            "admissao":"${dataForm}",
            "envio":"${tipoContato}"}`,
        )

        const parametrosFinal = Base64.encode(parametros)

        // history.push('/register/confirm-sms')
        await api
          .get(`wsAutenticacao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
          .then(res => {
            setConfigData({ ...configData, token: res.data.token, tipoContato })

            history.push('/register/confirm-sms')
          })
          .catch(res => {
            if (res.message === 'Network Error') {
              setErroProps({
                title: res.message,
                description: 'Falha na conexão como servidor',
              })
              setClicado(false)
              history.push('/erro')
            } else if (res.message === 'Request failed with status code 500') {
              setErroProps({
                title: 'Erro interno no servidor',
                description: res.message,
              })
              setClicado(false)
              history.push('/erro')
            } else if (res.message === 'Request failed with status code 401') {
              setTxtModal('Ops, algo deu errado. Tente novamente mais tarde.')
              setIsModalOpen(true)
              setClicado(false)
            } else if (res.message === 'Request failed with status code 408') {
              alert('Tempo de sessão expirado')
            } else if (res.message === 'Request failed with status code 404') {
              setTxtModal('Não localizamos os seus dados na base cadastral. Por favor, confira as informações digitadas ou entre em contato com a área de atendimento.')
              setIsModalOpen(true)
              setClicado(false)
            } else if (res.message === 'Request failed with status code 406') {
              setTxtModal('CPF já inscrito no plano. Para mais informações entre em contato com a área de atendimento.')
              setIsModalOpen(true)
              setClicado(false)
            } else if (res.message === 'Request failed with status code 417') {
              setTxtModal('Não foi possível enviar SMS. Verifique o telefone informado.')
              setIsModalOpen(true)
              setClicado(false)
            } else {
              setTxtModal('Ops, algo deu errado. Tente novamente mais tarde.')
              setIsModalOpen(true)
              setClicado(false)
            }
          })
      } catch (err) {
        formRef.current?.setErrors(
          getValidationErrors(err as Yup.ValidationError),
        )
      }
    },
    [
      setUserData,
      userData,
      configData,
      tipoContato,
      setConfigData,
      history,
      setErroProps,
    ],
  )

  const toggleAcceptTerms = useCallback(() => {
    setAcceptTerms(!acceptTerms)
  }, [acceptTerms, setAcceptTerms])

  const handleConfirmar = useCallback(event => {
    event.preventDefault()
    formRef.current?.submitForm()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>Informe seus dados para prosseguir:</strong>

          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              name: userData.name,
              cpf: userData.cpf,
              admission:
                userData.admission === undefined
                  ? ''
                  : userData.admission.split('-').reverse().join('/'),
              phone: userData.phone,
              email: userData.email,
            }}
          >
            <Input name="name" placeholder="Nome Completo" icon={FiUser} />
            <Input
              placeholder="CPF"
              name="cpf"
              icon={MdSecurity}
              type="tel"
              mask="cpf"
            />
            <Input
              icon={FiCalendar}
              name="admission"
              maxLength={10}
              placeholder="Data de admissão"
              mask="date"
            />
            <Input
              name="phone"
              mask="phone"
              prefix="+55 | "
              placeholder="Celular com DDD"
              icon={FiSmartphone}
            />
            <Input
              icon={FiMail}
              name="email"
              type="email"
              placeholder="E-mail"
            />
            <RadioButton>
              <label>Enviar código de confirmação por: </label>
              <div>
                <BtnContato
                  type="button"
                  isActive={tipoContato === 'S'}
                  onClick={() => setTipoContato('S')}
                >
                  SMS
                </BtnContato>
                <BtnContato
                  type="button"
                  isActive={tipoContato === 'E'}
                  onClick={() => setTipoContato('E')}
                >
                  E-mail
                </BtnContato>
              </div>
            </RadioButton>
            <InputHidden name="tipContato" value={tipoContato} type="hidden" />
          </Form>

          <div>
            <small>
              Ao continuar navegando no site, você concorda com as condições de{' '}
              <a
                href="https://staging-prev-pepsico.webflow.io/legal/politica-de-privacidade"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacidade
              </a>
              {' e '}
              <a
                href="https://staging-prev-pepsico.webflow.io/legal/termos-de-uso"
                target="_blank"
                rel="noopener noreferrer"
              >
                Termos de uso
              </a>
              {' do site Prev Pepsico.'}
            </small>
            <Switch
              onChange={toggleAcceptTerms}
              checked={acceptTerms}
              checkedIcon={false}
              uncheckedIcon={false}
              height={20}
              width={40}
              handleDiameter={16}
              offColor="#DEE3E1"
              offHandleColor="#fff"
              onHandleColor="#fff"
              onColor="#31D19E"
            />
          </div>
          <Line />
          <p>
            As informações constantes neste formulário serão utilizadas para
            processamento da sua solicitação, cumprimento de obrigações legais e
            regulatórias, de acordo com a nossa Política de Privacidade e na Lei
            Geral de Proteção de Dados – Lei nº 13.709/2018. Para mais detalhes
            sobre como tratamos seus dados pessoais acesse nossa Política de
            Privacidade em{' '}
            <a
              href="https://prevpepsico.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.prevpepsico.com.br
            </a>
            .
          </p>
        </Content>

        <Button
          type="submit"
          color="orange"
          onClick={handleConfirmar}
          disabled={!acceptTerms || clicado}
        >
          Próximo
        </Button>
        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          {txtModal}
          <BtnModal isActive onClick={() => handleCloseModal()}>
            Ok
          </BtnModal>
        </ModalBox>
      </Container>
    </>
  )
}

export default Register
