import React from 'react'

import Header from '../../components/Header'

import ImgTon from '../../assets/ton-pagina-adesao-2.svg'

import { Container, Content } from './styles'

const End: React.FC = () => {
  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>
            Pronto! A adesão ao Plano de Benefícios Pepsico foi concluída com
            sucesso!
          </strong>

          <img src={ImgTon} alt="img11" />

          <p>
            Agora é com a gente. <br />
            Vamos avaliar seus dados e em breve você receberá a confirmação da
            adesão por e-mail!
          </p>

          <div>
            <strong>ACESSE O NOSSO SITE:</strong>
            <a href="https://prevpepsico.com.br/">prevpepsico.com.br</a>
          </div>
        </Content>
      </Container>
    </>
  )
}

export default End
