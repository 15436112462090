import React, { useEffect } from 'react'
import { Switch } from 'react-router-dom'
import Route from './Route'
import BemVindo from '../pages/BemVindo'
import Simulation from '../pages/Simulation'
import Register from '../pages/Register'
import ConfirmSMS from '../pages/ConfirmSMS'
import Taxation from '../pages/Taxation'
import FatcaInfo from '../pages/FatcaInfo'
import NovoParticipante from '../pages/NovoParticipante'
import ParticipantsList from '../pages/ParticipantsList'
import Conclusion from '../pages/Conclusion'
import Resume from '../pages/Resume'
import End from '../pages/End'
import Erro from '../pages/Erro'
import NotFound from '../pages/NotFound'
import { ConfigData } from '../utils/interfaces'
import usePersistedState from '../hooks/usePersistedState'
import clientConfig from '../services/clientConfig'

const Routes: React.FC = () => {
  const { codCliente, tipo, plano } = clientConfig()

  const [configData, setConfigData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  useEffect(() => {
    setConfigData({
      ...configData,
      codCliente,
      tipo,
      plano,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Switch>
      <Route path="/" exact component={BemVindo} />
      <Route path="/simulation" component={Simulation} />
      <Route path="/register" exact component={Register} />
      <Route path="/register/confirm-sms" component={ConfirmSMS} />
      {/* <Route path="/taxation" component={Taxation} /> */}
      <Route path="/fatca" component={FatcaInfo} />
      <Route path="/new-participant" component={NovoParticipante} />
      <Route path="/participants-list" component={ParticipantsList} />
      <Route path="/resume" component={Resume} />
      <Route path="/conclusion" component={Conclusion} />
      <Route path="/end" component={End} />

      <Route path="/erro" component={Erro} />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

export default Routes
